var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center flex-wrap"},[_c('div',{staticClass:"mb-1 mr-2 w-100",staticStyle:{"max-width":"150px"}},[_c('label',[_vm._v(_vm._s(_vm.$t('global.from')))]),_c('b-form-datepicker',{attrs:{"value":_vm.todaysDate,"reset-button":true,"date-format-options":{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },"placeholder":"დან"},on:{"input":_vm.handleFromChange}})],1),_c('div',{staticClass:"mb-1 mr-2 w-100",staticStyle:{"max-width":"150px"}},[_c('label',[_vm._v(_vm._s(_vm.$t('global.to')))]),_c('b-form-datepicker',{attrs:{"value":_vm.todaysDate,"reset-button":true,"date-format-options":{
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      },"placeholder":"მდე"},on:{"input":_vm.handleToChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }